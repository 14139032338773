// Generated by Framer (b0f2619)

import { addFonts, cx, CycleVariantState, SVG, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["JYIptTzDE"];

const serializationHash = "framer-4RCAh"

const variantClassNames = {JYIptTzDE: "framer-v-c7nt6f"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "JYIptTzDE", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-c7nt6f", className, classNames)} data-framer-name={"Variant 1"} initial={variant} layoutDependency={layoutDependency} layoutId={"JYIptTzDE"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><SVG className={"framer-1b7wm1l"} data-framer-name={"Frame 29614102"} layout={"position"} layoutDependency={layoutDependency} layoutId={"PBFmvBpyP"} opacity={1} svg={"<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 200 38\"><path d=\"M 7.525 18.456 C 7.208 18.772 7.208 19.285 7.525 19.601 L 12.678 24.754 C 12.994 25.07 13.506 25.07 13.823 24.754 C 14.139 24.438 14.139 23.925 13.823 23.609 L 9.242 19.028 L 13.823 14.448 C 14.139 14.132 14.139 13.619 13.823 13.303 C 13.506 12.987 12.994 12.987 12.678 13.303 Z M 192.475 19.601 C 192.792 19.285 192.792 18.772 192.475 18.456 L 187.322 13.303 C 187.006 12.987 186.493 12.987 186.177 13.303 C 185.861 13.619 185.861 14.132 186.177 14.448 L 190.758 19.028 L 186.177 23.609 C 185.861 23.925 185.861 24.438 186.177 24.754 C 186.493 25.07 187.006 25.07 187.322 24.754 Z M 8.097 19.838 L 191.903 19.838 L 191.903 18.219 L 8.097 18.219 Z\" fill=\"rgb(0,0,0)\"></path></svg>"} svgContentId={3154853039} withExternalLayout/></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-4RCAh[data-border=\"true\"]::after, .framer-4RCAh [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4RCAh.framer-1lu0413, .framer-4RCAh .framer-1lu0413 { display: block; }", ".framer-4RCAh.framer-c7nt6f { height: 38px; overflow: hidden; position: relative; width: 200px; }", ".framer-4RCAh .framer-1b7wm1l { flex: none; height: 38px; left: calc(50.00000000000002% - 200px / 2); position: absolute; top: calc(50.00000000000002% - 38px / 2); width: 200px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 38
 * @framerIntrinsicWidth 200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerNeb7yNzbm: React.ComponentType<Props> = withCSS(Component, css, "framer-4RCAh") as typeof Component;
export default FramerNeb7yNzbm;

FramerNeb7yNzbm.displayName = "ValueError";

FramerNeb7yNzbm.defaultProps = {height: 38, width: 200};

addFonts(FramerNeb7yNzbm, [])